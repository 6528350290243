import {useEffect, useState} from "react";

import {TreeService} from "tree/service";
import {tree_view_adapter} from "tree/model";

import {useNavigateWithReload} from "base/navigation/hooks";

import {TreeForm} from "tree/presentational/form";
import {TreeDataFields} from "tree/presentational";
import {
    SectionCardHeaderAction,
    SubSectionCardHeader,
} from "base/ui/section/components";
import {DeleteItemDialog} from "base/delete/components";
import {PermitTypeTags} from "permit/presentational";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ParkIcon from "@mui/icons-material/Park";

const ViewOrUpdateTreeDataContent = ({permitId, tree, displayFees, disabled}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(() => {
        setError(null);
    }, []);

    const handleDelete = () => {
        TreeService.delete(tree.id).then(() => {
            navigate("", true);
        });
    };

    const handleFormSubmit = tree => {
        TreeService.update(tree_view_adapter(tree))
            .then(updatedTree => {
                console.log(updatedTree);
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const actions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
        />,
        <SectionCardHeaderAction
            key="delete"
            name="delete"
            text="Remover"
            icon={<DeleteIcon color="error" />}
            onClick={() => {
                setIsDeleteDialogOpen(true);
            }}
        />,
    ];

    const getComponent = mode => {
        if (mode === "view") {
            return <TreeDataFields tree={tree} displayFees={displayFees} />;
        }
        if (mode === "edit") {
            return (
                <TreeForm
                    permitId={permitId}
                    tree={tree}
                    displayFees={displayFees}
                    onSubmit={handleFormSubmit}
                    onCancel={() => {
                        setMode("view");
                    }}
                    error={error}
                />
            );
        }
    };

    const titleTags = (
        <PermitTypeTags
            types={[tree.felling && "felling", tree.cutting && "cutting"]}
            short
            justifyContent={"unset"}
        />
    );

    return (
        <Card sx={{border: 1, borderRadius: 2, borderColor: "grey.300"}} elevation={0}>
            <SubSectionCardHeader
                titleLabel="Árvore"
                titleValue={tree.code}
                titleTags={titleTags}
                icon={<ParkIcon />}
                actions={!disabled ? actions : null}
            />
            <CardContent>{getComponent(mode)}</CardContent>
            <DeleteItemDialog
                isDialogOpen={isDeleteDialogOpen}
                setIsDialogOpen={setIsDeleteDialogOpen}
                onDelete={handleDelete}
            />
        </Card>
    );
};

export default ViewOrUpdateTreeDataContent;
