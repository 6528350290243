import {useMapGeojsonGeometryLayerDataContext, useMapGeojsonLayerContext} from ".";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const MapGeojsonGeometryLayerTOCListItemContent = () => {
    const {
        layerConfig: {layer, legend},
    } = useMapGeojsonLayerContext();

    const {
        feature,
        loading,
        editable,
        setEditable,
        handleUpdateSave,
        handleUpdateCancel,
    } = useMapGeojsonGeometryLayerDataContext();

    const getActions = () => {
        if (legend?.options?.hideActions) {
            return null;
        }
        return editable ? (
            <>
                <Button
                    variant="contained"
                    size="small"
                    sx={{width: 100, fontSize: "0.6rem"}}
                    onClick={() => {
                        setEditable(false);
                        handleUpdateSave();
                    }}
                >
                    Salvar
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{width: 100, fontSize: "0.6rem"}}
                    onClick={() => {
                        setEditable(false);
                        handleUpdateCancel();
                    }}
                >
                    Cancelar
                </Button>
            </>
        ) : !feature || !feature.geometry ? (
            <Button
                variant="contained"
                size="small"
                sx={{width: 140, fontSize: "0.6rem"}}
                startIcon={<AddIcon />}
                onClick={() => {
                    setEditable(true);
                }}
            >
                Criar geometria
            </Button>
        ) : (
            <Button
                variant="contained"
                size="small"
                sx={{width: 140, fontSize: "0.6rem"}}
                startIcon={<EditIcon />}
                onClick={() => {
                    setEditable(true);
                }}
            >
                Editar geometria
            </Button>
        );
    };

    const getBorderColor = () => {
        return editable
            ? "#ff0000"
            : legend?.style?.color || layer?.style?.color || "grey.300";
    };

    return (
        legend && (
            <ListItem disablePadding sx={{p: 2}}>
                <Paper
                    elevation={0}
                    sx={{width: "100%", p: 1, border: 1, borderColor: getBorderColor()}}
                >
                    <Stack justifyContent="center" alignItems="center" spacing={1}>
                        <Typography variant="h6">{legend.label}</Typography>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            {loading ? (
                                <CircularProgress size={12} sx={{mt: 1, mr: 1}} />
                            ) : (
                                getActions()
                            )}
                        </Stack>
                    </Stack>
                </Paper>
            </ListItem>
        )
    );
};

export default MapGeojsonGeometryLayerTOCListItemContent;
