import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import {useConfigModule} from "base/ui/module/provider";
import {PermitService} from "permit/service";

import {ApprovedPermitSubPageMenu} from "permit/menu";
import {PageLayout} from "base/ui/main";

const ViewApprovedPermitPage = () => {
    const {id} = useParams();

    const [permit, setPermit] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();

    const {addToModuleFilter, setModuleBasePath, isLoading, setIsLoading} =
        useConfigModule();

    useEffect(() => {
        setIsLoading(true);
        setPermit(null);
        setModuleBasePath(`/permits/approved/list/${id}`);
        PermitService.get(id)
            .then(data => {
                addToModuleFilter({permit: data.id});
                setPermit(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error);
                setIsLoading(false);
            });
    }, [id, location.state?.lastRefreshDate]);

    return (
        <PageLayout
            menu={<ApprovedPermitSubPageMenu permit={permit} />}
            context={[permit]}
            isLoading={isLoading}
            error={error}
            subPage
        />
    );
};

export default ViewApprovedPermitPage;
