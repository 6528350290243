import {useGeojsonEditableLayer, useGeojsonLayer} from "base/map/leaflet/layer";
import {
    createLayerLegend,
    createLayerLegendDiscriminator,
    createLayerLegendDiscriminatorItem,
    createWMSLegendIcon,
} from "base/map/legend";

const popup = feature => {
    let data = feature.properties;
    let popupContent = `<b>Parcela: ${data["code"] ? data["code"] : "---"}</b>`;
    popupContent += "<ul class='attributes'>";
    popupContent += `<li><i>Proprietário/a</i>: ${data["holder_name"] || "-"}</li>`;
    popupContent += "</ul>";
    popupContent += `<div style="width: 100%; text-align: center;"><a href='/plots/all/list/${feature.id}/summary' target="_blank">Ver parcela</a></div>`;
    return popupContent;
};

export function createPlotLayer() {
    return useGeojsonLayer({
        style: {
            color: "#8bbf1b",
            fillColor: "#8bbf1b4d",
        },
        popup: popup,
        pane: 8,
    });
}

export function createPlotLegend() {
    return createLayerLegend({
        label: "Parcelas",
        icon: createWMSLegendIcon({
            type: "square",
        }),
    });
}

export function createPlotEditableLayer() {
    return useGeojsonEditableLayer({
        style: {
            color: "#1d721d",
            fillColor: "#1d721d4d",
        },
    });
}

export function createPlotEditableLegend({label = null, hideActions = false}) {
    return createLayerLegend({
        label,
        options: {
            hideActions,
        },
    });
}

export function createProcedureLegendDiscriminator() {
    return createLayerLegendDiscriminator({
        name: "Procedimento",
        field: "procedure",
        style: "points",
        entries: [
            createLayerLegendDiscriminatorItem({
                code: "procedure-violation",
                label: "Infracção",
                style: {
                    fillColor: "orange",
                },
                filter: val => val.includes("violation"),
            }),
            createLayerLegendDiscriminatorItem({
                code: "procedure-plantation",
                label: "Plantio",
                style: {
                    fillColor: "green",
                },
                filter: val => val.includes("plantation"),
            }),
            createLayerLegendDiscriminatorItem({
                code: "procedure-permit",
                label: "Autorização",
                style: {
                    fillColor: "blue",
                },
                filter: val => val.includes("permit"),
            }),
        ],
    });
}
