import {useList} from "base/entity/provider";
import {MapBase, useMapContext} from "base/map";
import {useEffect} from "react";

const mapStyle = {
    width: "100%",
    height: "650px",
};

const EntityListMap = () => {
    const {filter} = useList();
    const {updateMapFilter} = useMapContext();

    useEffect(() => {
        updateMapFilter(filter);
    }, [filter]);

    return <MapBase />;
};

export default EntityListMap;
