import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const StepperButtons = ({navigationStatus, onClick}) => {
    return (
        <Stack direction="row" px={2}>
            {navigationStatus.processComplete ? (
                <>
                    <Typography sx={{mt: 2, mb: 1}}>Processo concluido.</Typography>
                    <Stack direction="row" pt={2}>
                        <Box sx={{flex: "1 1 auto"}} />
                        {onClick.reset && (
                            <Button onClick={onClick.reset}>Reset</Button>
                        )}
                    </Stack>
                </>
            ) : (
                <>
                    {!navigationStatus.firstStep && (
                        <Button
                            variant="outlined"
                            onClick={onClick.back}
                            disabled={
                                navigationStatus.disableNavigation ||
                                navigationStatus.disableBackButton
                            }
                            hidden={!navigationStatus.firstStep}
                            sx={{mr: 1}}
                        >
                            Voltar
                        </Button>
                    )}

                    <Box sx={{flex: "1 1 auto"}} />

                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onClick.cancel}
                        disabled={
                            navigationStatus.disableNavigation ||
                            navigationStatus.processComplete
                        }
                    >
                        Cancelar processo
                    </Button>

                    <Button
                        variant="contained"
                        onClick={onClick.next}
                        disabled={
                            navigationStatus.disableNavigation ||
                            navigationStatus.disableNext ||
                            navigationStatus.processComplete ||
                            navigationStatus.disableCompleteButton
                        }
                        sx={{ml: 1}}
                    >
                        {navigationStatus.lastStep ? "Completar processo" : "Avançar"}
                    </Button>
                </>
            )}
        </Stack>
    );
};

export default StepperButtons;
