import {useMapBaseLayersContext} from "../layer";
import {MapGeojsonGeometryLayer, MapGeojsonLayer} from "../layer/geojson";
import {MapTileLayer} from "../layer/tile";

import {ImageOverlayLayer, TileLayer, WMSLayer} from "../leaflet/layer";

import {MapTOCList} from ".";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {MapWMSLayer} from "../layer/wms";

const MapTOC = () => {
    const {
        mainLayer: mainLayerConfig,
        layers: layersConfig,
        geometry: geometryConfig,
    } = useMapBaseLayersContext();

    console.log("CARTO >> Creating TOC", {layersConfig});

    const getLayerListItem = (layerConfig, index) => {
        console.log("CARTO >> Creating TOC entry", {index}, {layerConfig});
        if (
            layerConfig.layer instanceof TileLayer ||
            layerConfig.layer instanceof ImageOverlayLayer
        ) {
            return <MapTileLayer key={index} layerConfig={layerConfig} />;
        } else if (layerConfig.layer instanceof WMSLayer) {
            return <MapWMSLayer key={index} layerConfig={layerConfig} />;
        }
        return <MapGeojsonLayer key={index} layerConfig={layerConfig} />;
    };

    return (
        <MapTOCList>
            {geometryConfig && (
                <>
                    <MapGeojsonGeometryLayer layerConfig={geometryConfig} />
                    <Divider sx={{mt: 1}} />
                </>
            )}
            {mainLayerConfig && (
                <>
                    <MapGeojsonLayer key="main-layer" layerConfig={mainLayerConfig} />
                    <Divider sx={{mt: 1}} />
                </>
            )}
            {layersConfig.map((layerConfig, index) => {
                return getLayerListItem(layerConfig, index);
            })}
        </MapTOCList>
    );
};

export default MapTOC;
