import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Tag = ({
    content,
    color = "secondary.light",
    textColor = "inherit",
    style = {},
}) => {
    return (
        <Box
            sx={{
                mb: "3px",
                ml: "3px",
                px: "6px",
                py: "3px",
                backgroundColor: color,
                borderRadius: 1,
                color: textColor,
                alignSelf: "flex-start",
                ...style,
            }}
        >
            <Typography fontSize={"12px"}>{content}</Typography>
        </Box>
    );
};

export default Tag;
