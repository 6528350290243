import {useState, createContext, useContext, useEffect} from "react";

let ModuleConfigContext = createContext(null);

export default function ModuleConfigProvider({children}) {
    const [moduleFilter, setModuleFilterInternal] = useState({
        permit: null,
    });
    const [moduleBasePath, setModuleBasePath] = useState(null);
    const [moduleName, setModuleName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const addToModuleFilter = filter => {
        const newModuleFilter = {...moduleFilter, ...filter};

        // Compare objects to avoid duplicate calls with same filter
        if (JSON.stringify(moduleFilter) !== JSON.stringify(newModuleFilter)) {
            setModuleFilterInternal({...moduleFilter, ...filter});
        }
    };

    useEffect(() => {
        if (moduleBasePath) {
            setModuleName(moduleBasePath.split("/")[1]);
        }
    }, [moduleBasePath]);

    let value = {
        moduleName,
        moduleFilter,
        addToModuleFilter,
        moduleBasePath,
        setModuleBasePath,
        isLoading,
        setIsLoading,
    };

    return (
        <ModuleConfigContext.Provider value={value}>
            {children}
        </ModuleConfigContext.Provider>
    );
}

function useConfigModule() {
    return useContext(ModuleConfigContext);
}

export {useConfigModule};
