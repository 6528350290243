import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export const getFieldContentConfig = (
    fieldStatus,
    forcedImport,
    enableForcedImport,
    handleExportSubmit,
    handleForcedImportSubmit,
    handleImportSubmit
) => {
    const fieldContentMap = {
        not_exported: {
            statusTitle: "1/3. Exporte a autorização para a equipa da vistoria",
            statusInfo: (
                <>
                    <Typography variant="body2">
                        Você vai exportar os dados da autorização para que a equipa da
                        vistoria possa accessar a elos desde o telemóvel.
                    </Typography>
                    <Typography variant="body2" style={{marginTop: 0}}>
                        Após a exportação,
                        <Typography component="span" variant="body2" fontWeight="bold">
                            {" "}
                            o processo será bloqueado{" "}
                        </Typography>
                        até que os dados do campo sejam actualizados.
                    </Typography>
                </>
            ),
            statusAlert:
                "Verifique se os dados de autorização estão correctos antes de continuar.",
            textButton: "Exportar a autorização",
            onConfirm: handleExportSubmit,
        },
        exported_not_changed: {
            statusTitle: "2/3. Continue sem os dados do campo",
            statusInfo: (
                <>
                    <Typography variant="body2">
                        A autorização foi exportada para o telemóvel, mas os dados do
                        campo ainda não foram actualizados.
                    </Typography>
                    <Typography variant="body2" style={{marginTop: 0}}>
                        Você pode continuar o processo e adicionar os dados do campo
                        <Typography component="span" variant="body2" fontWeight="bold">
                            {" "}
                            manualmente{" "}
                        </Typography>
                        mais tarde mas
                        <Typography component="span" variant="body2" fontWeight="bold">
                            {" "}
                            não poderá importar os dados{" "}
                        </Typography>
                        do campo do telemóvel.
                    </Typography>
                    <Typography variant="body2">
                        Ou pode
                        <Typography component="span" variant="body2" fontWeight="bold">
                            {" "}
                            esperar que a equipa da vistoria actualize os dados do campo{" "}
                        </Typography>
                        para importá-los automaticamente.
                    </Typography>
                </>
            ),
            statusAlert:
                "Se tiver certeza de que deseja continuar o processo sem os dados do campo, acrescente o código da autorização.",
            textButton: "Continuar sem os dados do campo",
            isButtonDisabled: !forcedImport,
            onConfirm: handleForcedImportSubmit,
            input: (
                <TextField
                    id="outlined-basic"
                    label="Código da autorização"
                    variant="outlined"
                    onChange={event => enableForcedImport(event.target.value)}
                />
            ),
        },
        exported_and_changed: {
            statusTitle: "2/3. Importe os dados do campo registrados no telemóvel",
            statusAlert:
                "Os dados do campo já foram acrescentados no telemóvel. Agora você pode importar os dados do campo para continuar o processo.",
            alertSeverity: "info",
            textButton: "Importar os dados do campo",
            onConfirm: handleImportSubmit,
        },
        imported: {
            statusTitle: "3/3. Acrescente os dados da vistoria",
            statusAlert:
                "Os dados do campo já foram importados desde o telemóvel. Preencha os dados da vistoria e continue com o processo.",
            alertSeverity: "success",
        },
        forced_imported: {
            statusTitle: "3/3. Acrescente os dados da vistoria",
            // TO-DO: Si no se rellenan los campos de la visita no se puede avanzar. ¿Y si no hay datos de la visita porque no se ha realizado?
            statusAlert:
                "Nenhum dado de campo foi importado. Preencha os dados da vistoria e avance para o passo seguinte para acrescentar os dados do campo.",
            alertSeverity: "info",
        },
    };

    return (
        fieldContentMap[fieldStatus] || {
            statusAlert: "Estado do processo desconhecido.",
            alertSeverity: "error",
        }
    );
};
