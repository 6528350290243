import {useDomain} from "snimf/domain/provider";

import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {useTreeFormFields} from "tree/hooks";
import {
    FormCheckboxGroup,
    FormInputDecimal,
    FormInputInteger,
    FormInputText,
    FormSelect,
} from "base/form/components";
import Grid from "@mui/material/Grid";

const TreeDataFormFields = ({displayFees}) => {
    const {speciesDomain} = useDomain();
    const {felling, cutting, permitTypeOptions} = useTreeFormFields(displayFees);

    const fellingFields = (
        <>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="felling_fee"
                    label="Taxa de abate"
                    endAdornment={`${CURRENCY_SYMBOL}/m3`}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="felling_price"
                    label="Valor de abate"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled
                />
            </Grid>
        </>
    );

    const cuttingFields = (
        <>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="cutting_fee"
                    label="Taxa de serragem"
                    endAdornment={`${CURRENCY_SYMBOL}/toro`}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="cutting_price"
                    label="Valor de serragem"
                    endAdornment={CURRENCY_SYMBOL}
                    disabled
                />
            </Grid>
        </>
    );

    return (
        <Grid container direction="row" columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="species"
                    label="Espécie"
                    options={speciesDomain}
                    disabled={displayFees}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="quality" label="Qualidade" disabled />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="height"
                    label="Altura"
                    endAdornment="m"
                    disabled={displayFees}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="diameter"
                    label="Diámetro AP"
                    endAdornment="m"
                    disabled={displayFees}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputDecimal
                    name="volume"
                    label="Volume"
                    endAdornment="m3"
                    disabled
                />
            </Grid>
            {cutting ? (
                <Grid item xs={6}>
                    <FormInputInteger
                        name="number_of_logs"
                        label="Nº de toros"
                        disabled={displayFees}
                        rules={{required: "Este campo é obrigatório."}}
                    />
                </Grid>
            ) : (
                <Grid item xs={6} aria-hidden="true"></Grid>
            )}
            <Grid item xs={6}>
                <FormCheckboxGroup options={permitTypeOptions} required />
            </Grid>
            <Grid item xs={6} aria-hidden="true"></Grid>
            {felling && displayFees ? fellingFields : null}
            {cutting && displayFees ? cuttingFields : null}
        </Grid>
    );
};

export default TreeDataFormFields;
