import {useGeojsonLayer} from "base/map/leaflet/layer";
import {createLayerLegend, createWMSLegendIcon} from "base/map/legend";

const popup = feature => {
    let data = feature.properties;
    let popupContent = `<b>Árvore: ${data["code"] ? data["code"] : "---"}</b>`;
    popupContent += "<ul class='attributes'>";
    popupContent += "</ul>";
    return popupContent;
};

export function createTreeLayer() {
    return useGeojsonLayer({
        style: {
            type: "point",
            color: "#03f427",
            fillColor: "#03f4274d",
        },
        popup: popup,
        pane: 9,
    });
}

export function createTreeLegend({label = null}) {
    return createLayerLegend({
        label: label || "Árvores",
        icon: createWMSLegendIcon({
            type: "point",
        }),
    });
}
