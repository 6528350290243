import {PlotService} from "plot/service";
import {
    MapBaseLayersProvider,
    useMapEditableLayerConfig,
    useMapLayerConfig,
} from "base/map/layer";
import {
    baseLayerUrl,
    createBasePlotLayer,
    createBasePlotLegend,
    useSnimfMapConfig,
} from "snimf/map";
import {
    createPlotEditableLayer,
    createPlotEditableLegend,
    createPlotLayer,
    createPlotLegend,
    createProcedureLegendDiscriminator,
} from "plot/map";
import {MapProvider} from "base/map";
import {useList} from "base/entity/provider";

const PlotsMapProvider = ({children, plot = null, editablePlot = true}) => {
    // map config
    const {setSize} = useList();
    const {crs, crsType, tocOptions, mapOptions, baseLayers} = useSnimfMapConfig();

    let editablePlotLayerConfig = plot
        ? useMapEditableLayerConfig({
              load: PlotService.getFeature(plot.id),
              update: feature => {
                  console.log(`Updating ${plot.code}`, feature);
                  return PlotService.updateFeature(plot.id, feature);
              },
              layer: createPlotEditableLayer(),
              legend: createPlotEditableLegend({
                  label: `Parcela ${plot.code}`,
                  hideActions: !editablePlot,
              }),
          })
        : null;

    const plotLayer = createPlotLayer();
    const plotLegend = createPlotLegend();
    const procedureDiscriminator = createProcedureLegendDiscriminator();

    const plotLayerConfig = useMapLayerConfig({
        load: plot
            ? filter => {
                  return PlotService.getFeatures({
                      ...filter,
                      excluded_id: plot.id,
                  });
              }
            : filter => {
                  return PlotService.getFeatures({
                      ...filter,
                  }).then(featuresCollection => {
                      if (featuresCollection?.features) {
                          setSize(featuresCollection.features.length);
                      }
                      return featuresCollection;
                  });
              },
        layer: plotLayer,
        legend: plotLegend,
        discriminators: [procedureDiscriminator],
    });

    const baseLayerLoad = baseLayerUrl;

    const basePlotLayer = createBasePlotLayer();
    const basePlotLegend = createBasePlotLegend();
    const basePlotLayerConfig = useMapLayerConfig({
        load: baseLayerLoad,
        layer: basePlotLayer,
        legend: basePlotLegend,
    });

    // end map config

    return (
        <MapProvider
            crs={crs}
            crsType={crsType}
            baseLayers={baseLayers}
            tocOptions={tocOptions}
            mapOptions={mapOptions}
        >
            <MapBaseLayersProvider
                geometry={editablePlotLayerConfig}
                mainLayer={plotLayerConfig}
                layers={[basePlotLayerConfig]}
            >
                {children}
            </MapBaseLayersProvider>
        </MapProvider>
    );
};
export default PlotsMapProvider;
