const createNotification = (label, value) => {
    return {label: label, value: value};
};

const permitApplicationFieldsUtil = {
    checkTrees(trees, typeOfPermit) {
        if (trees.length > 0) {
            return trees.some(function (element) {
                return element[typeOfPermit] === true;
            });
        } else {
            return false;
        }
    },

    checkFeesInTrees(trees) {
        return trees.every(function (tree) {
            if (tree.felling) {
                if (!tree.felling_fee) {
                    return false;
                }
            }
            if (tree.cutting) {
                if (!tree.cutting_fee) {
                    return false;
                }
            }
            return true;
        });
    },

    checkFeesInMaterials(materials) {
        return materials.every(function (material) {
            return material.fee && material.price;
        });
    },

    createNotifications(fieldsArray) {
        return fieldsArray.map(([label, value]) => createNotification(label, value));
    },
};

export default permitApplicationFieldsUtil;
