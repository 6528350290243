import {
    MapGeojsonLayerProvider,
    MapGeojsonGeometryLayerDataProvider,
    MapGeojsonGeometryLayerTOCListItemContent,
} from ".";

const MapGeojsonGeometryLayer = ({layerConfig}) => {
    return (
        <MapGeojsonLayerProvider layerConfig={layerConfig}>
            <MapGeojsonGeometryLayerDataProvider>
                <MapGeojsonGeometryLayerTOCListItemContent />
            </MapGeojsonGeometryLayerDataProvider>
        </MapGeojsonLayerProvider>
    );
};

export default MapGeojsonGeometryLayer;
