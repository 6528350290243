import {PlotService} from "plot/service";
import {
    MapBaseLayersProvider,
    useMapEditableLayerConfig,
    useMapLayerConfig,
} from "base/map/layer";
import {
    baseLayerUrl,
    createBasePlotLayer,
    createBasePlotLegend,
    useSnimfMapConfig,
} from "snimf/map";
import {
    createPlotEditableLayer,
    createPlotEditableLegend,
    createPlotLayer,
    createPlotLegend,
    createProcedureLegendDiscriminator,
} from "plot/map";
import {MapProvider} from "base/map";
import {TreeService} from "tree/service";
import {createTreeLayer, createTreeLegend} from "tree/map";

const PlotTreesMapProvider = ({children, plot, permit}) => {
    // map config
    const {crs, crsType, tocOptions, mapOptions, baseLayers} = useSnimfMapConfig();

    let editablePlotLayerConfig = useMapEditableLayerConfig({
        load: PlotService.getFeature(plot.id),
        update: feature => {
            console.log(`Updating ${plot.code}`, feature);
            return PlotService.updateFeature(plot.id, feature);
        },
        layer: createPlotEditableLayer(),
        legend: createPlotEditableLegend({
            label: `Parcela ${plot.code}`,
            hideActions: true,
        }),
    });

    const treeLayer = createTreeLayer();
    const treeLegend = createTreeLegend({label: "Árvores da parcela"});

    const treeLayerConfig = useMapLayerConfig({
        load: filter => {
            return TreeService.getFeatures({
                ...filter,
                permit_id: permit.id,
            });
        },
        layer: treeLayer,
        legend: treeLegend,
    });

    const plotLayer = createPlotLayer();
    const plotLegend = createPlotLegend();
    const procedureDiscriminator = createProcedureLegendDiscriminator();

    const plotLayerConfig = useMapLayerConfig({
        load: filter => {
            return PlotService.getFeatures({
                ...filter,
                excluded_id: plot.id,
            });
        },
        layer: plotLayer,
        legend: plotLegend,
        discriminators: [procedureDiscriminator],
    });

    const baseLayerLoad = baseLayerUrl;

    const basePlotLayer = createBasePlotLayer();
    const basePlotLegend = createBasePlotLegend();
    const basePlotLayerConfig = useMapLayerConfig({
        load: baseLayerLoad,
        layer: basePlotLayer,
        legend: basePlotLegend,
    });

    // end map config

    return (
        <MapProvider
            crs={crs}
            crsType={crsType}
            baseLayers={baseLayers}
            tocOptions={tocOptions}
            mapOptions={mapOptions}
        >
            <MapBaseLayersProvider
                geometry={editablePlotLayerConfig}
                layers={[treeLayerConfig, plotLayerConfig, basePlotLayerConfig]}
            >
                {children}
            </MapBaseLayersProvider>
        </MapProvider>
    );
};
export default PlotTreesMapProvider;
