import {createContext, useContext, useEffect, useRef, useState} from "react";

let MapLayersContext = createContext(null);

export default function MapBaseLayersProvider({
    layers: customLayers = null,
    mainLayer: customMainLayer = null,
    geometry: customGeometry = null,
    children,
}) {
    const [layers, setLayers] = useState(customLayers);
    const [mainLayer, setMainLayer] = useState(customMainLayer);
    const [geometry, setGeometry] = useState(customGeometry);

    return (
        <MapLayersContext.Provider value={{layers, mainLayer, geometry}}>
            {children}
        </MapLayersContext.Provider>
    );
}

const useMapBaseLayersContext = () => {
    return useContext(MapLayersContext);
};

export {useMapBaseLayersContext};
