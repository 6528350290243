import {usePermitTypesWithColors} from "permit/hooks";
import {Tag} from "base/shared/components";
import Stack from "@mui/material/Stack";

const PermitTypeTags = ({types, short = false, ...props}) => {
    const {filteredTypesWithColors} = usePermitTypesWithColors(types);

    const getLabel = label => {
        if (short) return label.charAt(0);
        return label;
    };

    return (
        <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent={short ? "right" : "unset"}
            {...props}
        >
            {filteredTypesWithColors?.map((type, index) => {
                return (
                    <Tag
                        key={index}
                        content={getLabel(type.label)}
                        color={type.color}
                        textColor={type.contrastTextColor}
                        style={
                            short
                                ? {
                                      borderRadius: "50%",
                                      border: "solid 1px white",
                                      px: "8px",
                                      my: 0,
                                      mr: "-8px",
                                  }
                                : null
                        }
                    />
                );
            })}
        </Stack>
    );
};

export default PermitTypeTags;
