import {useOutletContext} from "react-router-dom";

import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil, NumberUtil} from "base/format/utilities";

import {PaperComponent} from "base/ui/containers";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const ViewApprovedPermitTypesClearing = () => {
    const {permit} = useOutletContext();

    return permit ? (
        <Stack spacing={1}>
            <PaperComponent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Data de início",
                            DateUtil.formatDate(permit?.clearing_start_date)
                        )}
                        {FieldUtil.getSectionField(
                            "Beneficiário/a do desbravamento",
                            permit?.clearing_beneficiary
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Data de caducidade",
                            DateUtil.formatDate(permit?.clearing_end_date)
                        )}
                    </Grid>
                </Grid>
            </PaperComponent>
            <PaperComponent>
                <Grid container>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Área a desbravar",
                            NumberUtil.formatDecimal(permit?.clearing_area),
                            "ha"
                        )}
                        {FieldUtil.getSectionField(
                            "Taxa para desbravamento",
                            NumberUtil.formatInteger(permit?.clearing_fee),
                            `${CURRENCY_SYMBOL}/ha`
                        )}
                        {FieldUtil.getSectionField(
                            "Valor de pago",
                            NumberUtil.formatInteger(permit?.clearing_price),
                            CURRENCY_SYMBOL
                        )}
                    </Grid>
                </Grid>
            </PaperComponent>
        </Stack>
    ) : null;
};

export default ViewApprovedPermitTypesClearing;
