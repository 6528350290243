import {createTree, createTrees, tree_api_adapter, trees_api_adapter} from "tree/model";
import {createEntityService} from "base/entity/service";

const basePath = "/api/app/trees";

const entityService = createEntityService(
    basePath,
    createTree,
    createTrees,
    tree_api_adapter,
    trees_api_adapter
);

const TreeService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, null, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(tree) {
        return entityService.create(tree);
    },

    update(tree) {
        return entityService.update(tree);
    },

    delete(id) {
        return entityService.delete(id);
    },
};

export default TreeService;
