import {SubSectionCardHeader} from "base/ui/section/components";
import {MaterialDataFields} from ".";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";

const MaterialCard = ({material, displayFees = true}) => {
    return (
        <Card sx={{border: 1, borderRadius: 2, borderColor: "grey.300"}} elevation={0}>
            <SubSectionCardHeader
                titleLabel="Material"
                titleValue={material.code}
                icon={<ForestOutlinedIcon />}
            />
            <CardContent>
                <MaterialDataFields material={material} displayFees={displayFees} />
            </CardContent>
        </Card>
    );
};

export default MaterialCard;
