import {useEffect, useState} from "react";

import {MaterialService} from "material/service";
import {material_view_adapter} from "material/model";

import {useNavigateWithReload} from "base/navigation/hooks";

import {MaterialForm} from "material/presentational/form";
import {MaterialDataFields} from "material/presentational";
import {
    SectionCardHeaderAction,
    SubSectionCardHeader,
} from "base/ui/section/components";
import {DeleteItemDialog} from "base/delete/components";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";

const ViewOrUpdateMaterialDataContent = ({
    permitId,
    material,
    displayFees,
    disabled,
}) => {
    const navigate = useNavigateWithReload();

    const [mode, setMode] = useState("view");
    const [error, setError] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(() => {
        setError(null);
    }, []);

    const handleDelete = () => {
        MaterialService.delete(material.id).then(() => {
            navigate("", true);
        });
    };

    const handleFormSubmit = material => {
        MaterialService.update(material_view_adapter(material))
            .then(updatedMaterial => {
                console.log(updatedMaterial);
                setMode("view");
                navigate("", true);
            })
            .catch(error => {
                console.log(error);
                setError(error);
            });
    };

    const actions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
        />,
        <SectionCardHeaderAction
            key="delete"
            name="delete"
            text="Remover"
            icon={<DeleteIcon color="error" />}
            onClick={() => {
                setIsDeleteDialogOpen(true);
            }}
        />,
    ];

    const getComponent = mode => {
        if (mode === "view") {
            return <MaterialDataFields material={material} displayFees={displayFees} />;
        }
        if (mode === "edit") {
            return (
                <MaterialForm
                    permitId={permitId}
                    material={material}
                    displayFees={displayFees}
                    onSubmit={handleFormSubmit}
                    onCancel={() => {
                        setMode("view");
                    }}
                    error={error}
                />
            );
        }
    };

    return (
        <Card sx={{border: 1, borderRadius: 2, borderColor: "grey.300"}} elevation={0}>
            <SubSectionCardHeader
                titleLabel="Material"
                titleValue={material.code}
                icon={<ForestOutlinedIcon />}
                actions={!disabled ? actions : null}
            />
            <CardContent>{getComponent(mode)}</CardContent>
            <DeleteItemDialog
                isDialogOpen={isDeleteDialogOpen}
                setIsDialogOpen={setIsDeleteDialogOpen}
                onDelete={handleDelete}
            />
        </Card>
    );
};

export default ViewOrUpdateMaterialDataContent;
