import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import {MapTOCListItemIcon} from ".";

import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Tooltip from "@mui/material/Tooltip";
import ErrorUtil from "base/error/utilities/ErrorUtil";

const MapLayerTOCListItem = ({
    legend,
    layerStyle = null,
    visible,
    setVisible,
    loading = false,
    error = null,
    secondaryAction = null,
    discriminatorLegend = null,
}) => {
    return (
        <>
            <ListItem
                disablePadding
                sx={{pl: 2}}
                className="LayerMenuListItem"
                secondaryAction={secondaryAction}
            >
                <Box>
                    {error ? (
                        <Tooltip title={ErrorUtil.getMessage(error)}>
                            <WarningAmberOutlinedIcon
                                color="error"
                                sx={{fontSize: 16, mt: 1, mr: 1}}
                            />
                        </Tooltip>
                    ) : loading ? (
                        <CircularProgress size={12} sx={{mt: 1, mr: 1}} />
                    ) : (
                        <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{"aria-labelledby": "layer"}}
                            checked={visible}
                            onChange={event => {
                                setVisible(event.target.checked);
                            }}
                            className="LayerMenuCheckbox"
                        />
                    )}
                </Box>
                <MapTOCListItemIcon icon={legend.icon} defaultStyle={layerStyle} />
                <ListItemText primary={legend.label} />
            </ListItem>
            {visible && !loading && !error ? discriminatorLegend : null}
        </>
    );
};

export default MapLayerTOCListItem;
