import {useWatch} from "react-hook-form";
import {useDomain} from "snimf/domain/provider";
import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {MaterialUtil} from "material/utils";
import {
    FormInputDecimal,
    FormInputInteger,
    FormInputText,
    FormSelect,
} from "base/form/components";

import Grid from "@mui/material/Grid";

const MaterialDataFormFields = ({displayFees}) => {
    const {speciesDomain, materialTypeDomain} = useDomain();

    const type = useWatch({
        name: "type",
    });

    return (
        <Grid container direction="row" columnSpacing={1}>
            <Grid item xs={6}>
                <FormSelect
                    name="species"
                    label="Espécie"
                    options={speciesDomain}
                    disabled={displayFees}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText name="quality" label="Qualidade" disabled />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="type"
                    label="Tipo"
                    options={materialTypeDomain}
                    disabled={displayFees}
                    rules={{required: "Este campo é obrigatório."}}
                />
            </Grid>
            <Grid item xs={6}>
                {MaterialUtil.displayLength(type) ? (
                    <FormInputDecimal
                        name="length"
                        label="Longitude das vigas"
                        endAdornment="m"
                        disabled={displayFees}
                        rules={{required: "Este campo é obrigatório."}}
                    />
                ) : (
                    <FormInputInteger
                        name="quantity"
                        label="Quantidade"
                        endAdornment={MaterialUtil.getUnitsForQuantity(type)}
                        disabled={displayFees}
                        rules={{required: "Este campo é obrigatório."}}
                    />
                )}
            </Grid>
            {displayFees ? (
                <>
                    <Grid item xs={6}>
                        <FormInputDecimal
                            name="fee"
                            label="Taxa de transporte"
                            endAdornment={MaterialUtil.getUnitsForFee(type)}
                            rules={{required: "Este campo é obrigatório."}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormInputDecimal
                            name="price"
                            label="Valor de transporte"
                            endAdornment={CURRENCY_SYMBOL}
                            disabled
                        />
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default MaterialDataFormFields;
