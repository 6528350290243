import {SubSectionCardHeader} from "base/ui/section/components";
import {TreeDataFields} from ".";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ParkIcon from "@mui/icons-material/Park";

const TreeCard = ({tree, displayFees = true}) => {
    return (
        <Card sx={{border: 1, borderRadius: 2, borderColor: "grey.300"}} elevation={0}>
            <SubSectionCardHeader
                titleLabel="Árvore"
                titleValue={tree.code}
                icon={<ParkIcon />}
            />
            <CardContent>
                <TreeDataFields tree={tree} displayFees={displayFees} />
            </CardContent>
        </Card>
    );
};

export default TreeCard;
