import {ViewTreesTable} from "tree/presentational";
import {ViewTreesContent} from ".";
import {Spinner} from "base/shared/components";
import Stack from "@mui/material/Stack";

const ViewTreesSection = ({
    permit,
    displayFees = true,
    hideNewButton = false,
    disabled = false,
}) => {
    return !permit ? (
        <Spinner key="spinner" />
    ) : (
        <Stack spacing={1}>
            <ViewTreesTable
                key="table"
                permitId={permit?.id}
                trees={permit.tree_set}
                displayFees={displayFees}
                disabled={hideNewButton || disabled}
            />
            <ViewTreesContent
                key="details"
                permitId={permit?.id}
                trees={permit.tree_set}
                displayFees={displayFees}
                disabled={disabled}
            />
        </Stack>
    );
};

export default ViewTreesSection;
