import {useMapGeojsonLayerContext} from ".";
import {MapLegendTOCDiscriminatorActions} from "../../legend";

import Divider from "@mui/material/Divider";

import DownloadIcon from "@mui/icons-material/Download";
import {ActionsMenu, MenuAction} from "base/ui/menu";
import {useMapLayerDiscriminatorContext} from "./MapGeojsonLayerDiscriminatorProvider";

const MapGeojsonLayerTOCListItemActions = () => {
    const {downloadData} = useMapGeojsonLayerContext();
    const {discriminators} = useMapLayerDiscriminatorContext();

    /*
    // NOT IMPLEMENTED YET
    let downloadAction = (
        <MenuAction
            id="download-layer"
            key="download-layer"
            icon={<DownloadIcon />}
            text="Descargar"
            handleClick={downloadData}
        />
    ); */
    const isVisible = discriminators && discriminators.length > 2;

    return (
        isVisible && (
            <ActionsMenu size="small">
                <MapLegendTOCDiscriminatorActions />
                <Divider />
                {/* downloadAction */}
            </ActionsMenu>
        )
    );
};

export default MapGeojsonLayerTOCListItemActions;
