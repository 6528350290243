import {FormProvider, useForm} from "react-hook-form";

import {FormUtil} from "base/form/utilities";

import {TreeDataFormFields} from ".";
import {AlertError} from "base/error/components";
import {EntityForm} from "base/entity/components/form";
import Stack from "@mui/material/Stack";

const TreeForm = ({
    permitId,
    tree = null,
    displayFees = true,
    error = null,
    onSubmit,
    onCancel = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(tree?.id),
        code: FormUtil.getFormValue(tree?.code),
        species: FormUtil.getFormValue(tree?.species),
        quality: FormUtil.getFormValue(tree?.quality),
        felling: FormUtil.getFormValue(tree?.felling),
        cutting: FormUtil.getFormValue(tree?.cutting),
        height: FormUtil.getFormValue(tree?.height),
        diameter: FormUtil.getFormValue(tree?.diameter),
        volume: FormUtil.getFormValue(tree?.volume),
        felling_fee: FormUtil.getFormValue(tree?.felling_fee),
        felling_price: FormUtil.getFormValue(tree?.felling_price),
        number_of_logs: FormUtil.getFormValue(tree?.number_of_logs),
        cutting_fee: FormUtil.getFormValue(tree?.cutting_fee),
        cutting_price: FormUtil.getFormValue(tree?.cutting_price),
        geom: FormUtil.getFormValue(tree?.geom),
        permit: FormUtil.getFormValue(tree?.permit),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const {
        formState: {isDirty},
    } = formMethods;

    const handleFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            code: FormUtil.getDataValue(data.code),
            species: FormUtil.getDataValue(data.species),
            quality: FormUtil.getDataValue(data.quality),
            felling: FormUtil.getDataValue(data.felling),
            cutting: FormUtil.getDataValue(data.cutting),
            height: FormUtil.getDataValue(data.height),
            diameter: FormUtil.getDataValue(data.diameter),
            volume: FormUtil.getDataValue(data.volume),
            felling_fee: FormUtil.getDataValue(data.felling_fee),
            felling_price: FormUtil.getDataValue(data.felling_price),
            number_of_logs: FormUtil.getDataValue(data.number_of_logs),
            cutting_fee: FormUtil.getDataValue(data.cutting_fee),
            cutting_price: FormUtil.getDataValue(data.cutting_price),
            geom: FormUtil.getDataValue(data.geom),
            permit: permitId,
        });
    };

    return (
        <FormProvider {...formMethods}>
            <Stack spacing={1}>
                <AlertError error={error} />
                <EntityForm
                    onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                    onCancel={onCancel}
                >
                    <TreeDataFormFields displayFees={displayFees} />
                </EntityForm>
            </Stack>
        </FormProvider>
    );
};

export default TreeForm;
