import {usePlantsTable} from "plant/data";
import {SectionCard} from "base/ui/section/components";
import {CreatePlantDataContent} from "plant/container";
import {SimpleTable} from "base/table/components";
import {NoDataContainer} from "base/shared/components";
import Stack from "@mui/system/Stack";

const ViewPlantationPlantsTable = ({plantationId, plants, allowEdit = true}) => {
    const {tableColumns} = usePlantsTable();

    return (
        <SectionCard title="Espécies plantadas">
            <Stack spacing={3}>
                {plants.length ? (
                    <SimpleTable dataRows={plants} tableColumns={tableColumns} />
                ) : (
                    <NoDataContainer text="Este plantio ainda não tem plantas." />
                )}

                {allowEdit && <CreatePlantDataContent plantationId={plantationId} />}
            </Stack>
        </SectionCard>
    );
};

export default ViewPlantationPlantsTable;
