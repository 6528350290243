import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity/components/container";
import {PlotBasicDataSection} from "plot/presentational";
import {SectionCard} from "base/ui/section/components";
import Box from "@mui/material/Box";
import {MapBase} from "base/map";
import {PlotTreesMapProvider} from "plot/map";

const ViewApprovedPermitPlotSubPage = () => {
    const context = useOutletContext();
    const entity = context[0];

    let sections = [
        <SectionCard title="Parcela" key="plot">
            <PlotBasicDataSection plot={entity?.plot} />
        </SectionCard>,
    ];
    if (entity?.plot) {
        sections.push(
            <SectionCard title="Mapa">
                <Box sx={{border: 1, borderColor: "grey.400"}}>
                    <PlotTreesMapProvider plot={entity?.plot} permit={entity}>
                        <MapBase />
                    </PlotTreesMapProvider>
                </Box>
            </SectionCard>
        );
    }

    return entity && <EntityViewSubPage sections={sections} />;
};

export default ViewApprovedPermitPlotSubPage;
