import {FormDatePickerCombo, FormInputText} from "base/form/components";
import Grid from "@mui/material/Grid";

const PermitApplicationCuttingFormFields = ({disabled}) => {
    return (
        <Grid container columnSpacing={1}>
            <FormDatePickerCombo
                startDateName={"cutting_start_date"}
                startDateLabel={"Data de início"}
                endDateName={"cutting_end_date"}
                endDateLabel={"Data de caducidade"}
                required={true}
                disabled={disabled}
            />

            <Grid item xs={12} sm={6}>
                <FormInputText
                    name="cutting_beneficiary"
                    label="Beneficiário/a"
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default PermitApplicationCuttingFormFields;
