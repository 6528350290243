import {MapBase} from "base/map";
import {SectionCard} from "base/ui/section/components";
import {PlotsMapProvider} from "plot/map";

import Box from "@mui/material/Box";

const PlotMapSection = ({plot, editablePlot = true}) => {
    return (
        <SectionCard title="Mapa">
            <Box sx={{border: 1, borderColor: "grey.400"}}>
                <PlotsMapProvider plot={plot} editablePlot={editablePlot}>
                    <MapBase />
                </PlotsMapProvider>
            </Box>
        </SectionCard>
    );
};

export default PlotMapSection;
