import {useOutletContext} from "react-router-dom";

import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil} from "base/format/utilities";
import {CURRENCY_SYMBOL} from "base/format/config/i18n";
import {useTransportMaterialTable} from "material/data";

import {MaterialCard} from "material/presentational";
import {PaperComponent} from "base/ui/containers";
import {SectionCard} from "base/ui/section/components";
import {SimpleTable} from "base/table/components";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const ViewApprovedPermitTypesTransport = () => {
    const {permit} = useOutletContext();

    const {tableColumns} = useTransportMaterialTable(true);
    const lastRow = {
        id: 3,
        label: "Total",
        value: `${permit?.transport_price} ${CURRENCY_SYMBOL}`,
        colSpan: 5,
    };

    return permit ? (
        <Stack spacing={1}>
            <PaperComponent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Data de início",
                            DateUtil.formatDate(permit?.transport_start_date)
                        )}
                        {FieldUtil.getSectionField(
                            "Beneficiário/a",
                            permit?.transport_beneficiary
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Data de caducidade",
                            DateUtil.formatDate(permit?.transport_end_date)
                        )}
                        {FieldUtil.getSectionField(
                            "Destino",
                            permit?.transport_destination
                        )}
                    </Grid>
                </Grid>
            </PaperComponent>

            {permit?.material_set ? (
                <>
                    <SectionCard title="Materiais">
                        <SimpleTable
                            dataRows={permit.material_set}
                            tableColumns={tableColumns}
                            lastRow={permit?.transport_price ? lastRow : null}
                        />
                    </SectionCard>

                    {permit?.material_set.map(material => (
                        <div key={material.code} id={`${material.code}`}>
                            <MaterialCard material={material} />
                        </div>
                    ))}
                </>
            ) : null}
        </Stack>
    ) : null;
};

export default ViewApprovedPermitTypesTransport;
