import {createContext, useContext, useEffect, useState} from "react";
import {useMapGeojsonLayerContext} from ".";
import {useMapContext} from "base/map";

let MapGeojsonGeometryLayerDataContext = createContext(null);

export default function MapGeojsonGeometryLayerDataProvider({children = null}) {
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);

    const {mapObjectRef, mapCRSType} = useMapContext();
    const {
        layerConfig: {load, update, layer},
    } = useMapGeojsonLayerContext();

    const [feature, setFeature] = useState(null);

    useEffect(() => {
        console.log("CARTO >> Creating layer", {layer});
        layer.create(mapObjectRef);
        loadData();
    }, []);

    useEffect(() => {
        if (feature) {
            console.log("CARTO >> Changing layer geometry", {geometry: feature});
            if (!feature["crs"]) {
                feature["crs"] = mapCRSType;
            }
            layer.update(feature);
            layer.fit();
        }
    }, [feature]);

    useEffect(() => {
        console.log("CARTO >> Editing layer geometry", {layer}, {editing: editable});
        editable ? layer.openEdition() : layer.closeEdition();
    }, [editable]);

    const loadData = () => {
        setLoading(true);
        console.log("CARTO >> Loading geometry", {load});

        const loadDataCall = typeof load === "function" ? load() : load;
        loadDataCall.then(feature => {
            setFeature(feature);
            setLoading(false);
        });
    };

    const handleUpdateSave = () => {
        update(layer.getEditedGeom()).then(updatedFeature => {
            console.log("CARTO >> Updated geometry", {updatedFeature});
            setFeature(updatedFeature);
        });
        layer.closeEdition();
    };

    const handleUpdateCancel = () => {
        //update(geom);
        layer.closeEdition();
    };

    return (
        <MapGeojsonGeometryLayerDataContext.Provider
            value={{
                feature,
                setFeature,
                loading,
                editable,
                setEditable,
                handleUpdateSave,
                handleUpdateCancel,
            }}
        >
            {children}
        </MapGeojsonGeometryLayerDataContext.Provider>
    );
}

const useMapGeojsonGeometryLayerDataContext = () => {
    return useContext(MapGeojsonGeometryLayerDataContext);
};

export {useMapGeojsonGeometryLayerDataContext};
