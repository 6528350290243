import {FormProvider, useForm} from "react-hook-form";

import {FormUtil} from "base/form/utilities";

import {MaterialDataFormFields} from ".";
import {AlertError} from "base/error/components";
import {EntityForm} from "base/entity/components/form";
import Stack from "@mui/material/Stack";

const MaterialForm = ({
    permitId,
    material = null,
    displayFees = true,
    error = null,
    onSubmit,
    onCancel = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(material?.id),
        code: FormUtil.getFormValue(material?.code),
        species: FormUtil.getFormValue(material?.species),
        quality: FormUtil.getFormValue(material?.quality),
        type: FormUtil.getFormValue(material?.type),
        quantity: FormUtil.getFormValue(material?.quantity),
        length: FormUtil.getFormValue(material?.length),
        fee: FormUtil.getFormValue(material?.fee),
        price: FormUtil.getFormValue(material?.price),
        permit: FormUtil.getFormValue(material?.permit),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const {
        formState: {isDirty},
    } = formMethods;

    const handleFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            code: FormUtil.getDataValue(data.code),
            species: FormUtil.getDataValue(data.species),
            quality: FormUtil.getDataValue(data.quality),
            type: FormUtil.getDataValue(data.type),
            quantity: FormUtil.getDataValue(data.quantity),
            length: FormUtil.getDataValue(data.length),
            fee: FormUtil.getDataValue(data.fee),
            price: FormUtil.getDataValue(data.price),
            permit: permitId,
        });
    };

    return (
        <FormProvider {...formMethods}>
            <Stack spacing={1}>
                <AlertError error={error} />
                <EntityForm
                    onSubmit={formMethods.handleSubmit(handleFormSubmit)}
                    onCancel={onCancel}
                >
                    <MaterialDataFormFields displayFees={displayFees} />
                </EntityForm>
            </Stack>
        </FormProvider>
    );
};

export default MaterialForm;
