import {SubPageMenu, SubPageMenuListItemButton} from "base/ui/menu";

import {BiTrip} from "react-icons/bi";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FolderOpenIconOutlined from "@mui/icons-material/FolderOpenOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";

const ApprovedPermitSubPageMenu = ({permit}) => {
    const basePath = `/permits/approved/list/${permit?.id}`;

    const secondary = (
        <>
            <strong>{permit?.holder?.name || "Sem titular"}</strong>
            <br />
            {permit?.district || "Sem distrito"}
        </>
    );

    return (
        <SubPageMenu
            headerTitle="Autorização"
            headerText={`${permit?.code}`}
            headerSecondary={secondary}
        >
            <SubPageMenuListItemButton
                key="permit-summary"
                to={`${basePath}/summary`}
                text="Resumo"
                icon={<InfoOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-holder"
                to={`${basePath}/holder`}
                text="Titular e contactos"
                icon={<ContactPageOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-plot"
                to={`${basePath}/plot`}
                text="Parcela"
                icon={<PlaceOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-inspection"
                to={`${basePath}/inspection`}
                text="Vistoria"
                icon={
                    <BiTrip
                        style={{
                            fontSize: "24px",
                            lineHeight: 0,
                        }}
                    />
                }
            />
            <SubPageMenuListItemButton
                key="permit-types"
                to={`${basePath}/types/overview`}
                resolvedPathName={`${basePath}/types`}
                text="Guias"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-billing"
                to={`${basePath}/billing`}
                text="Facturação"
                icon={<RequestQuoteOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                key="permit-documents"
                to={`${basePath}/documents`}
                text="Documentos"
                icon={<FolderOpenIconOutlined />}
            />
        </SubPageMenu>
    );
};

export default ApprovedPermitSubPageMenu;
