import {createContext, useContext, useEffect, useRef, useState} from "react";
import {useMapConfig} from "./MapConfig";

let MapContext = createContext(null);

export default function MapProvider({
    crs: customCRS = null,
    crsType: customCRSType = null,
    baseLayers: customBaseLayers = null,
    mapOptions: customMapOptions = {},
    controlOptions: customControlOptions = {},
    tocOptions: customTocOptions = {},
    children,
}) {
    const {
        crs: defaultCRS,
        crsType: defaultCRSType,
        baseLayers: defaultBaseLayers,
        mapOptions: defaultMapOptions,
        controlOptions: defaultControlOptions,
        tocOptions: defaultTocOptions,
    } = useMapConfig();

    const [selectedBaseLayer, setSelectedBaseLayer] = useState(null);
    const [mapFilter, setMapFilter] = useState({});
    const [buffer, setBuffer] = useState(null);

    const [baseLayers, setBaseLayers] = useState(customBaseLayers || defaultBaseLayers);

    const [mapCRS, setMapCRS] = useState(customCRS || defaultCRS);
    const [mapCRSType, setMapCRSType] = useState(customCRSType || defaultCRSType);

    const [mapOptions, setMapOptions] = useState({
        ...defaultMapOptions,
        ...customMapOptions,
    });

    const [controlOptions, setControlOptions] = useState({
        ...defaultControlOptions,
        ...customControlOptions,
    });

    const [tocOptions, setTocOptions] = useState({
        ...defaultTocOptions,
        ...customTocOptions,
    });

    const mapDOMRef = useRef(null);
    const mapObjectRef = useRef(null);

    useEffect(() => {
        setSelectedBaseLayer(baseLayers[0]);
    }, []);

    // And this component have to check changes in moduleFilter to update mapFilter
    useEffect(() => {
        setMapFilter({...mapFilter, buffer: buffer});
    }, [buffer]);

    const updateMapFilter = filter => {
        if (!filter || !Object.keys(filter).length) {
            setMapFilter({buffer: buffer});
        } else {
            setMapFilter({...mapFilter, ...filter});
        }
    };

    return (
        <MapContext.Provider
            value={{
                mapDOMRef,
                mapObjectRef,
                selectedBaseLayer,
                setSelectedBaseLayer,
                baseLayers,
                mapCRS,
                setMapCRS,
                mapCRSType,
                setMapCRSType,
                setBaseLayers,
                mapFilter,
                updateMapFilter,
                buffer,
                setBuffer,
                mapOptions,
                setMapOptions,
                controlOptions,
                setControlOptions,
                tocOptions,
                setTocOptions,
            }}
        >
            {children}
        </MapContext.Provider>
    );
}

const useMapContext = () => {
    return useContext(MapContext);
};

export {useMapContext};
