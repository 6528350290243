import {useState} from "react";
import {useAuth} from "base/user/provider";
import {islandsUtil} from "snimf/location/utilities";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const ActiveIslandSelector = () => {
    const auth = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIsland, setSelectedIsland] = useState(auth.activeIslandsSwitch());
    const openSectorMenu = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOnChange = (event, value) => {
        setSelectedIsland(value);
        auth.toggleIsland(event, value);
    };

    const getTooltip = () => {
        return auth.activeIslandsSwitch();
    };

    const getIcon = () => {
        const activeIsland = auth.activeIslandsSwitch();
        const label = islandsUtil.getIslandLabel(activeIsland);

        return (
            <Typography style={{color: "primary", fontSize: "50%"}}>{label}</Typography>
        );
    };

    return (
        <div>
            <Tooltip title={getTooltip()}>
                <IconButton
                    onClick={handleClick}
                    sx={{
                        height: 40,
                        width: 40,
                        border: "1px solid",
                        borderColor: "primary.main",
                        ml: 2,
                        bgcolor: "white",
                        ":hover": {
                            bgcolor: "white", // theme.palette.primary.main
                            color: "primary.main",
                        },
                    }}
                    aria-controls={openSectorMenu ? "profile-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSectorMenu ? "true" : undefined}
                >
                    {getIcon()}
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openSectorMenu}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <FormControl>
                    <RadioGroup
                        value={selectedIsland}
                        name="active-islands-radio-buttons-group"
                        onChange={handleOnChange}
                    >
                        <MenuItem>
                            <FormControlLabel
                                value="São Tomé"
                                control={<Radio />}
                                label="São Tomé"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                value="Príncipe"
                                control={<Radio />}
                                label="Príncipe"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                value="São Tomé e Príncipe"
                                control={<Radio />}
                                label="São Tomé e Príncipe"
                            />
                        </MenuItem>
                    </RadioGroup>
                </FormControl>
            </Menu>
        </div>
    );
};

export default ActiveIslandSelector;
