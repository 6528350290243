import {useViolationTypesWithColors} from "violation/hooks";
import {Tag} from "base/shared/components";
import Stack from "@mui/material/Stack";

const ViolationTypeTags = ({types}) => {
    const {filteredTypesWithColors} = useViolationTypesWithColors(types);

    return (
        <Stack direction="row" flexWrap="wrap">
            {filteredTypesWithColors?.map((type, index) => {
                return (
                    <Tag
                        key={index}
                        content={type.label}
                        color={type.color}
                        textColor={type.contrastTextColor}
                    />
                );
            })}
        </Stack>
    );
};

export default ViolationTypeTags;
