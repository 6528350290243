//Date formats

export const DATE_FORMATS = {
    CLIENT_DATEFORMAT: "dd/MM/yyyy",
    CLIENT_DATEMONTHFORMAT: "MM/yyyy",
    CLIENT_DATETIMEFORMAT: "dd/MM/yyyy HH:mm",
    CLIENT_TIMEFORMAT: "HH:mm",
    FILENAME_DATETIMEFORMAT: "yyyyMMddHHmmss",
    API_DATEFORMAT: "yyyy-MM-dd",
};

export {pt as USED_LOCALE} from "date-fns/locale";

// Number formats

export const DECIMAL_SEPARATOR = ",";
export const THOUSAND_SEPARATOR = "\xa0"; // Non-breaking space
export const CURRENCY_SYMBOL = "Db";
export const localCurrencyFormatter = new Intl.NumberFormat("pt-ST", {
    currencySign: "standard",
    style: "currency",
    currency: "STN",
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    // @ts-ignore
    trailingZeroDisplay: "stripIfInteger",
});
export const localIntegerFormatter = new Intl.NumberFormat("pt-ST", {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
    // @ts-ignore
    trailingZeroDisplay: "stripIfInteger",
});
export const localDecimalFormatter = new Intl.NumberFormat("pt-ST", {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    // signDisplay: "always",
});
