import {useState} from "react";
import {useLocation, useOutletContext} from "react-router-dom";

import {FileUpload, useDownload} from "base/file/utilities";
import {useNavigateWithReload} from "base/navigation/hooks";
import {PermitApplicationService} from "permitapplication/service";
import {permit_view_adapter} from "permit/model";

import {PermitApplicationPaymentFormFields, PermitForm} from "./form";
import {FormContainer} from "base/form/components";
import {DownloadPDFButton} from "base/pdf/presentational";
import Stack from "@mui/material/Stack";

const PermitApplicationPaymentStep = () => {
    const download = useDownload();
    const navigate = useNavigateWithReload();

    const {permit, isStepEditable} = useOutletContext();
    const [error, setError] = useState(null);

    const basePath = useLocation();
    const fileFields = ["payment_receipt"];

    const handleDownload = () => {
        if (permit) {
            return PermitApplicationService.downloadPermit(permit.id).then(response => {
                return download(response)
                    .then(() => navigate("", true))
                    .catch(error => setError(error));
            });
        } else {
            return Promise.reject("Nenhuma autorização foi selecionada.");
        }
    };

    const handleFormSubmit = (updatedPermit, callback = null) => {
        const objectContainsFiles = Object.values(updatedPermit).some(
            value => value instanceof File
        );

        if (!objectContainsFiles) {
            updatePermitApplication(updatedPermit).then(() => {
                FileUpload.callCallback(callback, true);
                navigate(basePath, true);
            });
        } else {
            FileUpload.uploadFiles(updatedPermit, permit, fileFields)
                .then(fileUploadResults => {
                    FileUpload.updateFormWithFileIds(
                        updatedPermit,
                        fileUploadResults,
                        fileFields
                    );
                    return updatePermitApplication(updatedPermit);
                })
                .then(() => {
                    FileUpload.callCallback(callback, true);
                    navigate(basePath, true);
                })
                .catch(fileError => {
                    FileUpload.callCallback(callback, false);
                    console.log({fileError});
                    setError(fileError.error);
                });
        }
    };

    const updatePermitApplication = updatedPermit => {
        return PermitApplicationService.update(
            permit_view_adapter(updatedPermit)
        ).catch(error => {
            console.error(error);
            setError(error);
        });
    };

    return permit ? (
        <Stack spacing={3}>
            <PermitForm permit={permit} onSubmit={handleFormSubmit} error={error}>
                <FormContainer>
                    <PermitApplicationPaymentFormFields disabled={!isStepEditable} />
                </FormContainer>
            </PermitForm>
            <Stack alignItems="center">
                <DownloadPDFButton
                    handleGeneratePDF={handleDownload}
                    text="Imprimir autorização"
                    color="secondary"
                    disabled={!permit?.payment_receipt}
                />
            </Stack>
        </Stack>
    ) : null;
};

export default PermitApplicationPaymentStep;
