import {MapView} from ".";
import {MapTOC} from "./toc";
import {MapActionsToolbar} from "./actions";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const MapBase = () => {
    return (
        <Stack direction="row" sx={{width: "100%"}}>
            <MapView />
            <Box sx={{minWidth: "300px"}}>
                <MapActionsToolbar />
                <MapTOC />
            </Box>
        </Stack>
    );
};
export default MapBase;
