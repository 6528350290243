import {useEffect, useState} from "react";
import {useDomain} from "snimf/domain/provider";

export const PERMIT_TYPES_COLORS = [
    {color: "#376a47", contrastTextColor: "#FFFFFF"},
    {color: "#375c6a", contrastTextColor: "#FFFFFF"},
    {color: "#545c97", contrastTextColor: "#FFFFFF"},
    {color: "#4E7897", contrastTextColor: "#FFFFFF"},
    {color: "grey.500", contrastTextColor: "#000000"},
];

function usePermitTypesWithColors(types) {
    const {permitTypeDomain: permitTypes} = useDomain();

    const [permitTypesWithColor, setPermitTypesWithColor] = useState([]);

    useEffect(() => {
        const typesWithColors = permitTypes.map((type, index) => ({
            ...type,
            ...(index < PERMIT_TYPES_COLORS.length
                ? PERMIT_TYPES_COLORS[index]
                : PERMIT_TYPES_COLORS[PERMIT_TYPES_COLORS.length - 1]),
        }));
        setPermitTypesWithColor(typesWithColors);
    }, [permitTypes]);

    const getFilteredTypesWithColors = () => {
        return permitTypesWithColor.filter(type => types.includes(type.value));
    };

    const filteredTypesWithColors = getFilteredTypesWithColors();

    return {permitTypesWithColor, filteredTypesWithColors};
}

export {usePermitTypesWithColors};
