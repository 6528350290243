import {useOutletContext} from "react-router-dom";

import {FieldUtil} from "base/ui/section/utilities";
import {DateUtil, NumberUtil} from "base/format/utilities";
import {useCuttingTreesTable} from "tree/data";

import {TreeCard} from "tree/presentational";
import {PaperComponent} from "base/ui/containers";
import {SectionCard} from "base/ui/section/components";
import {SimpleTable} from "base/table/components";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const ViewApprovedPermitTypesCutting = () => {
    const {permit, treesForCutting} = useOutletContext();

    const {tableColumns} = useCuttingTreesTable();
    const lastRow = {
        id: 3,
        label: "Total",
        value: `${NumberUtil.formatCurrency(permit?.cutting_price)}`,
        colSpan: 4,
    };

    return permit ? (
        <Stack spacing={1}>
            <PaperComponent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Data de início",
                            DateUtil.formatDate(permit?.cutting_start_date)
                        )}
                        {FieldUtil.getSectionField(
                            "Beneficiário/a",
                            permit?.cutting_beneficiary
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {FieldUtil.getSectionField(
                            "Data de caducidade",
                            DateUtil.formatDate(permit?.cutting_end_date)
                        )}
                    </Grid>
                </Grid>
            </PaperComponent>

            <SectionCard title="Árvores">
                <SimpleTable
                    dataRows={treesForCutting}
                    tableColumns={tableColumns}
                    lastRow={permit?.cutting_price ? lastRow : null}
                />
            </SectionCard>

            {treesForCutting?.map(tree => (
                <div key={tree.code} id={`${tree.code}`}>
                    <TreeCard tree={tree} />
                </div>
            ))}
        </Stack>
    ) : null;
};

export default ViewApprovedPermitTypesCutting;
