import {useCallback, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";

import {useList} from "base/entity/provider";
import {SearchBox} from "base/search/components";

const EntityFilterForm = () => {
    const {filter, setFilter, setPage} = useList();

    const formMethods = useForm({
        defaultValues: {
            search: filter?.search || "",
        },
    });

    useEffect(() => {
        formMethods.reset({search: filter?.search || ""});
    }, [filter]);

    const handleChange = useCallback(
        attributeValue => {
            setPage(1);
            setFilter({...filter, ...attributeValue});
        },
        [filter]
    );

    return (
        <FormProvider {...formMethods}>
            <SearchBox
                name="search"
                onChangeHandler={value => handleChange({search: value})}
            />
        </FormProvider>
    );
};

export default EntityFilterForm;
